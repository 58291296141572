<!-- eslint-disable vue/no-v-html -->
<template>
	<b-modal
		ref="productAddedModal"
		hide-footer
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom-cart'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered>
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate(titleKey) }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close()">
				×
			</button>
		</template>
		<div
			v-if="cartItemsHasData && totalsHasData && !loading">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? 'no-gutters' : ''"
				class="row">
				<div class="col-md-6 order-2 order-md-1">
					<ul class="list-group">
						<li
							v-for="(product, key) in cartItems"
							:key="key"
							class="list-group-item px-0 border-0">
							<div class="row">
								<div class="col-3 pr-0 d-flex align-items-center">
									<img
										class="mw-100"
										:src="product.image"
										alt="">
								</div>
								<div
									:class="{ 'pr-0': ['md'].includes(windowWidth) }"
									class="col">
									<div class="row">
										<div class="col-12">
											<div class="h6 font-weight-bold text-primary">
												{{ translate(product.code_name) }}
												<template v-if="product.backorder_info && product.backorder_info.will_backorder">
													<i
														v-b-tooltip
														:title="translate('will_be_backordered')"
														class="fas fa-exclamation-triangle text-warning text-small" />
												</template>
												<span
													v-if="product.replaceable"
													v-b-tooltip.hover
													:title="translate('pick_flavors')"
													class="badge badge-green pointer">
													{{ translate('replaceable') }}
													<i class="fas fa-info-circle" />
												</span>
											</div>
											<div class="row">
												<div class="col-4 mb-1">
													<div class="font-weight-bold">
														{{ translate('subtotal') }}:
													</div>
												</div>
												<div class="col-8">
													<div class="text-right font-weight-light">
														{{ product.sub_total }}
													</div>
												</div>
											</div>
											<div
												v-if="!blackList.includes(product.sku)"
												class="row mb-1">
												<div class="col">
													<quantity-select
														:init-qty="qty[product.item_id]"
														:select-limit="10"
														@change="updateQuantity($event, product)" />
												</div>
											</div>
											<div
												v-else
												class="row">
												<div class="col-4 mb-1">
													<div class="font-weight-bold">
														{{ translate('qty_abbrev') }}:
													</div>
												</div>
												<div class="col-8">
													<div class="text-right font-weight-light">
														{{ qty[product.item_id] }}
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-3">
													<a
														class="pointer font-weight-light text-small text-blue"
														@click="removeProduct($event, product)">
														{{ translate('remove') }}
													</a>
												</div>
												<div class="col-9 text-right">
													<span
														v-if="product.not_individual_purchase"
														v-b-tooltip.hover
														:title="translate('not_available_individually_message')"
														class="text-success text-small font-weight-light pointer">
														{{ translate('not_available_individually') }}
														<i class="fas fa-info-circle" />
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="col-md-6 order-1 order-md-2">
					<b-alert
						show
						variant="secondary"
						class="text-dark">
						<h4 class="text-center text-uppercase">
							{{ translate('order_summary') }}
						</h4>
						<h5
							v-if="totalVolume && !isAgencySite"
							class="text-center font-weight-normal">
							{{ translate('total_volume') }}: {{ totalVolume }}
						</h5>
						<h5
							v-if="totalWeeks && isDistributor"
							class="text-center font-weight-normal">
							{{ translate('weeks') }}: {{ totalWeeks }}
						</h5>
						<div
							class="font-weight-normal">
							<template v-for="(specificTotal, key) in totals">
								<div :key="key">
									<div class="row no-gutters pb-2">
										<div class="col-7">
											<div
												:class="{
													'text-medium': smallTextTotals.includes(key),
												}"
												class="text-muted">
												{{ key == 'shipping' ? translate('shipping_and_handling') : translate(key) }}
												<discount-detail
													v-if="key === 'discount'"
													id="`discount-widget`"
													:discount-detail="discountDetail" />
											</div>
										</div>
										<div class="col-5">
											<div
												:class="{
													'text-discount': ['discount', 'points'].includes(key),
													'text-muted': !['discount', 'points'].includes(key),
													'text-medium': smallTextTotals.includes(key),
												}"
												class="text-right">
												{{ specificTotal }}
											</div>
										</div>
									</div>
								</div>
							</template>
							<div class="row">
								<div class="col">
									<div class="border-top">
										<div class="row pt-2">
											<div class="col-4">
												<div class="h5 mb-0">
													{{ translate('total') }}
												</div>
											</div>
											<div class="col-8">
												<div class="h5 text-right mb-0">
													{{ total }}
												</div>
											</div>
										</div>
										<div
											v-if="!!exchange"
											class="row">
											<div class="col h6 exchange-text text-right">
												{{ exchange }}*
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<free-shipping-progress
							v-if="freeShippingInfo"
							class="mt-3"
							:status="freeShippingInfo.status"
							:current-amount="freeShippingInfo.current_amount"
							:remaining-amount="freeShippingInfo.remaining_amount"
							:free-shipping-at="freeShippingInfo.free_shipping_at" />
						<free-products-week-widget
							v-if="Object.keys(freeProductsBvsInfo).length"
							class="mt-3"
							:total-bv="Number(totalVolume)"
							:order-activation-weeks="totalWeeks"
							:promo-info="freeProductsBvsInfo" />
						<cart-points
							v-if="showCredits"
							:points="points"
							alert-class="secondary-darker mt-3"
							@use-points="$emit('use-points')"
							@remove-points="$emit('remove-points')"
							@update-split-wallet="$emit('update-split-wallet')" />
						<div
							v-if="applyFreeBottle"
							class="row mt-2">
							<div class="col d-flex justify-content-center">
								<div class="alert alert-green w-100 pl-1">
									<ul class="fa-ul mb-0">
										<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate(freeBottleMessage)" /></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col px-5">
								<button
									type="button"
									class="btn btn-primary btn-block my-2"
									@click="handleCheckoutRedirect(preCheckoutAlert)">
									{{ translate('proceed_to_checkout') }}
								</button>
							</div>
						</div>
						<div class="row">
							<div class="col ">
								<div class="text-center">
									<a
										class="pointer text-muted text-medium"
										@click="close()">
										{{ translate('or_continue_shopping') }}
									</a>
								</div>
							</div>
						</div>
						<!-- 30-day money back guarantee -->
						<div
							v-if="isAgencySite"
							class="row">
							<div class="col">
								<div class="mt-4 mb-2">
									<p class="h6 py-0 my-0 text-center">
										<i class="fa fa-check-circle-o mr-1" />
										{{ translate('thirty_day_money_back_guarantee') }}
									</p>
								</div>
							</div>
						</div>
						<div
							v-if="!!exchange"
							class="row mt-3">
							<div class="col exchange-text text-small">
								{{ translate('exchange_disclaimer') }}
							</div>
						</div>
					</b-alert>
					<div
						v-if="automaticDiscountPercentage > 0"
						class="row mt-2">
						<div class="col d-flex justify-content-center">
							<div class="alert alert-green w-100 pl-1">
								<ul class="fa-ul mb-0">
									<li><span class="fa-li"><i class="fas fa-gift mr-2" /></span> <span v-html="translate('apply_automatic_discount_message', { percentage: automaticDiscountPercentage })" /></li>
								</ul>
							</div>
						</div>
					</div>
					<possible-discounts
						v-if="showMessages"
						:possible-discount-messages="possibleDiscountMessages"
						:free-products-info="freeProductsInfo"
						:promotions="promotions" />
					<b-alert
						:show="hasBackorderProducts"
						variant="warning"
						class="my-2">
						<div v-html="translate('backorder_info')" />
					</b-alert>
				</div>
			</div>
			<hr
				v-if="cartRelatedItemsHasData"
				class="separator">
			<div
				v-if="cartRelatedItemsHasData"
				:class="['xs', 'sm'].includes(windowWidth) ? 'no-gutters' : ''"
				class="row mx-3">
				<div class="col-12 text-center">
					<span class="text-primary">{{ translate('related_products_message') }}</span>
				</div>
				<div
					class="card-body py-1 text-center"
					:class="{
						'scrolling-wrapper custom-scrollbar': ['xs', 'sm'].includes(windowWidth),
						'px-0': cartRelatedItems.length <= 5,
						'px-3': cartRelatedItems.length > 5,
					}">
					<div
						v-for="(product, key) in cartRelatedItems"
						:key="key"
						class="d-inline-block pointer mx-4"
						style="max-width: 90px;">
						<img
							:src="product.attributes.thumbnail"
							width="65px"
							@click="goToProductDetail(product.attributes.decoded_name)">
						<p class="text-center mb-0">
							{{ translate(product.attributes.code_name) }}
						</p>
						<p class="text-muted mb-0 text-medium">
							{{ getProductPrice(product.attributes) }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('empty_cart')"
			:loading="loading"
			:has-data="cartItemsHasData && totalsHasData" />
	</b-modal>
</template>
<script>
import EventBus from '@/util/eventBus';
import CartPoints from '@/components/Cart/CartPoints';
import CartDisplay from '@/mixins/CartDisplay';
import AgencySite from '@/mixins/AgencySite';
import DiscountDetail from '@/components/Cart/DiscountDetail';
import FreeShippingProgress from '@/components/FreeShippingProgress/index.vue';
import FreeProductsWeekWidget from '@/components/FreeProductsWeekWidget';

export default {
	name: 'ProductAddedModal',
	components: {
		FreeProductsWeekWidget,
		FreeShippingProgress,
		CartPoints,
		DiscountDetail,
	},
	mixins: [CartDisplay, AgencySite],
	props: {
		showCredits: {
			type: Boolean,
			default: false,
		},
		showPreModal: {
			type: Boolean,
			default: false,
		},
		preCheckoutAlert: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			titleKey: 'item_added',
		};
	},
	mounted() {
		EventBus.$on('updateLayoutStoreTotals', (titleKey) => {
			this.titleKey = titleKey || 'item_added';
			this.$refs.productAddedModal.show();
		});
	},
	beforeDestroy() {
		EventBus.$off('updateLayoutStoreTotals');
	},
	methods: {
		goToProductDetail(decodedName) {
			this.$router.push({ name: this.getRedirectName('ProductDetails'), params: { productName: decodedName } });
			this.close();
		},
		close() {
			this.$refs.productAddedModal.hide();
		},
		getProductPrice(product) {
			return product.price.formatted_amount;
		},
	},
};
</script>
<style>
	/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
	.modal-lg-custom-cart {
		max-width: 700px !important;
		width: 700px !important;
	}
</style>
<style scoped>
	.text-blue {
		color: #13637f !important;
	}
	.custom-scrollbar::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
		background-color: #F5F5F5;
		border-radius: 10px;
	}
	.custom-scrollbar::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: #F5F5F5;
	}
	.custom-scrollbar::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(223, 112, 61, 0.6);
	}
	.separator {
		margin-left: -8px;
		margin-right: -8px;
	}
</style>
